window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
require('bootstrap');
require('gdpr-cookie');

require('owl.carousel/dist/owl.carousel.min');

$('#mainSlider').owlCarousel({
    loop: true,
    margin: 0,
    nav: true,
    items: 1,
    dots: true,
});

$('#slider-mostra').owlCarousel({
    loop: true,
    margin: 0,
    nav: true,
    autoWidth: true,
    dots: false,
    responsive: {
        0: {
            items: 1
        },
        1000: {
            items: 4
        }
    }
});

$('.owl-carousel.photo-slider').owlCarousel({
    loop: true,
    margin: 0,
    nav: false,
    dots: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true
});
$('#headerPartners').owlCarousel({
    loop: true,
    margin: 0,
    nav: false,
    dots: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true
});

$('#slider-products').owlCarousel({
    loop: true,
    margin: 15,
    nav: true,
    dots: false,
    responsive: {
        0: {
            items: 2
        },
        400: {
            items: 3
        },
        600: {
            items: 4
        },
        1000: {
            items: 5,
            loop: false
        }
    }
});

$('.carosello-loghi').owlCarousel({
    loop: true,
    margin: 15,
    nav: false,
    dots: true,
    autoplay: true,
    responsive: {
        0: {
            items: 2
        },
        400: {
            items: 3
        },
        600: {
            items: 4
        },
        1000: {
            items: 5
        }
    }
});

$('#slider-graziea').owlCarousel({
    loop: true,
    margin: 15,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
        0: {
            items: 2
        },
        400: {
            items: 3
        },
        600: {
            items: 4
        },
        1000: {
            items: 5,
            loop: false
        }
    }
});

$('#timelineMobile').owlCarousel({
    loop: true,
    margin: 0,
    nav: false,
    dots: true,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        }
    }
});


// Cache selectors
var lastId,
    topMenu = $("#top-menu"),
    topMenuHeight = $('.navbar').outerHeight() + 10,
    menuItems = topMenu.find("a");

var scrollItems = menuItems.map(function () {
    var $this = $(this);
    if (!$this.hasClass('btn')) {
        var item = $($this.attr("href"));
        if (item.length) {
            return item;
        }
    }
});
// Bind click handler to menu items
// so we can get a fancy scroll animation
menuItems.click(function (e) {
    var href = $(this).attr("href"),
        offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
    $('html, body').stop().animate({
        scrollTop: offsetTop
    }, 300);
    e.preventDefault();
});

// Bind to scroll
$(window).scroll(function () {
    // Get container scroll position
    var fromTop = $(this).scrollTop() + topMenuHeight;

    // Get id of current scroll item
    var cur = scrollItems.map(function () {
        if ($(this).offset().top < fromTop)
            return this;
    });
    // Get the id of the current element
    cur = cur[cur.length - 1];
    var id = cur && cur.length ? cur[0].id : "";

    if (lastId !== id) {
        lastId = id;
        // Set/remove active class
        menuItems
            .parent().removeClass("active")
            .end().filter("[href='#" + id + "']").parent().addClass("active");
    }
});

$('.cta-regala-visita').on('click', function () {
    fbq('track', 'InitiateCheckout');
});


(($) => {
    'use strict';
    window.dataLayer = window.dataLayer || [];

    $.gdprcookie.init({
        title: "🍪 Accetti la Cookie Policy?",
        message: 'Su questo sito utilizziamo cookie tecnici per farlo funzionare bene, e cookie di profilazione e statistici che ci servono per personalizzare i messaggi informativi o promozionali, oltre che a fare analisi del traffico (che adesso non sono attivi). Accetti di ricevere anche questi cookie? Se vuoi saperne di più consulta l\'informativa sulla nostra <a href="https://www.legatumori.mi.it/cookie-policy/" target="_blank">Cookie Policy</a> ',
        delay: 600,
        acceptBtnLabel: "Accetta",
        advancedBtnLabel: "Personalizza",
        acceptBeforeAdvanced: ["essential", "statistics", "marketing"],
        acceptAfterAdvanced: ["essential"],
        cookieName: "cookiePrefsNastroRosa",
        cookieTypes: [
            {
                type: 'Necessari',
                value: 'essential',
                description: 'Cookie necessari per il corretto funzionamento del sito',
                checked: true,
            },
            {
                type: 'Statistici',
                value: 'statistics',
                description: 'Cookie utili per il tracciamento delle statistiche',
                checked: true,
            },
            {
                type: 'Marketing',
                value: 'marketing',
                description: 'Cookie utili per il marketing',
                checked: true,
            }
        ]
    });

    $(document.body)
        .on("gdpr:accept", function () {
            if ($.gdprcookie.preference("statistics")) {
                dataLayer.push({
                    'event': 'cookieConsentStatistics',
                    'cookieConsentLevel': 1,
                    'anonymizeIp': 0,
                });
            }
            if ($.gdprcookie.preference("marketing")) {
                dataLayer.push({
                    'event': 'cookieConsentMarketing',
                    'cookieConsentLevel': 2,
                    'anonymizeIp': 0,
                });
            }
        });

    if ($.gdprcookie.preference("statistics")) {
        dataLayer.push({
            'event': 'cookieConsentStatistics',
            'cookieConsentLevel': 1,
            'anonymizeIp': 0,
        });
    }
    if ($.gdprcookie.preference("marketing")) {
        dataLayer.push({
            'event': 'cookieConsentMarketing',
            'cookieConsentLevel': 2,
            'anonymizeIp': 0,
        });
    }

})(window.jQuery);